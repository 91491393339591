import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2f04d60a9a05420a8a853117a98e3841@o1018463.ingest.sentry.io/5984203",
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0.25,
  allowUrls: [
    /https?:\/\/((www)\.)?jumpagrade\.com/
  ]
});
